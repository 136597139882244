import { isMobile, isTablet } from '../shared/responsive';

function clearSidebarPreferences() {
  localStorage.clear();
}

function collapseSidebar() {
  $('.main-site').removeClass('sidebar--expanded');
}

function expandSidebar() {
  $('.main-site').addClass('sidebar--expanded');
}

function isTitleBarHover() {
  return $('.title-bar').is(':hover');
}

function loadSidebarPreferences() {
  if (localStorage.getItem('sidebarCollapsed') === 'true') {
    $('.main-site').addClass('sidebar--collapsed');
  }
}

function menuHoverOff() {
  if (isTitleBarHover()) return;

  collapseSidebar();
}

function menuHoverOn() {
  if (isTitleBarHover()) return;

  expandSidebar();
}

function saveSidebarPreferences() {
  localStorage.setItem('sidebarCollapsed', $('.main-site').hasClass('sidebar--collapsed'));
}

function toggleSidebar() {
  const $mainSite = $('.main-site');
  clearSidebarPreferences();

  if (isMobile()) {
    $mainSite.toggleClass('mobile-menu--expanded');
  } else {
    $mainSite.toggleClass('sidebar--collapsed');
    collapseSidebar();
    saveSidebarPreferences();
  }
}

$(document).ready(function() {
  $('.main-header-region').hover(menuHoverOn, menuHoverOff);
  $('.toggle-mobile-menu').click(toggleSidebar);
  $('.toggle-sidebar').click(toggleSidebar);
  loadSidebarPreferences();
});
