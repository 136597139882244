const config = {
  tabletMinWidth: 721,
  tabletMaxWidth: 1024
};

export function isMobile() {
  return window.innerWidth < config.tabletMinWidth;
}

export function isTablet() {
  return config.tabletMinWidth <= window.innerWidth && window.innerWidth <= config.tabletMaxWidth;
}
